import React from "react";
import { AiFillCopy } from "react-icons/ai";
// import { useClipboard } from "@mantine/hooks";
import { useClipboard } from "@mantine/hooks";

const CopyToClipboard = ({ text }) => {
  const clipboard = useClipboard({ timeout: 500 });
  const handleClick = () => {
    clipboard.copy(text);
  };
  return <AiFillCopy onClick={handleClick} style={{ cursor: "pointer" }} />;
};
export default CopyToClipboard;
