import React, { useState } from "react";
import HeartIcon from "../../assets/svg/HeartIcon";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ModalWrapper from "../../containers/ModalWrapper.js";

export default function CasinoCard({ data }) {
  const { isAuth } = useSelector((state) => state.auth);
  const [showAuthModals, setShowAuthModals] = useState(false);
  const navigate = useNavigate();
  const handleGameClick = (redirectionUrl) => {
    if (isAuth) {
      navigate(redirectionUrl);
    } else {
      setShowAuthModals(true);
    }
  };
  return (
    <div onClick={() => handleGameClick(data.href)} className="gameDiv">
      <div className="shadow rounded position-relative">
        <img src={data?.cover?.src} alt={data?.cover?.alt || ""} />
        <h5>{data.gameName}</h5>
        <span className="like-btn">
          <HeartIcon />
        </span>
      </div>
      {showAuthModals && (
        <ModalWrapper
          defaultShow={"login"}
          setShowAuthModals={setShowAuthModals}
        />
      )}
    </div>
  );
}
