import React from "react";
import { useSelector } from "react-redux";
import { Accordion, Button, Container } from "react-bootstrap";

import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Header from "../../../../containers/Header";
import Footer from "../../../../containers/Footer";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";

import DepositImg from "../../../../assets/images/deposit.png";
import WithdrawImg from "../../../../assets/images/withdraw.png";

const Faq = () => {
  const { isAuth } = useSelector((state) => state.auth);
  return (
    <div>
      <div>{isAuth ? <HeaderAfterLogin /> : <Header />}</div>

      <main className="main">
        <div className="container">
          <h2 className="sectionTitle">FAQ</h2>
        </div>

        <Container>
          <div className="promotiion-content">
            <Accordion>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  Services and Login information:
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    The following restrictions apply to the use of the Service:
                    <ul>
                      <li>
                        - You accept full responsibility for any unauthorized
                        use of the Service by you or anyone else. You are
                        responsible for any use of your bank or other payment
                        instrument (such as UPI) by you or anyone else.
                      </li>
                      <li>
                        - You shall not (or attempt to) purchase, sell, rent or
                        give away your Account, create an Account using a false
                        identity or information, or on behalf of someone other
                        than yourself; You shall not use the Service if you have
                        previously been removed by AxBet, or previously been
                        banned from playing on AxBet.
                      </li>
                      <li>
                        - You shall not use the Service to advertise, or
                        solicit, or transmit any commercial advertisements,
                        including chain letters, junk, or spam e-mail or
                        repetitive or misleading messages to anyone.
                      </li>
                    </ul>
                  </p>
                  <h3>Login information and Your Account -</h3>
                  <p>
                    You shall not share the Account or the Login Information,
                    nor let anyone else access your Account or do anything else
                    that might jeopardize the security of your Account. In the
                    event you become aware of or reasonably suspect any breach
                    of security, including without limitation any loss, theft,
                    or unauthorized disclosure of the Login Information, you
                    must immediately notify AxBet and modify your Login
                    Information. You are solely responsible for maintaining the
                    confidentiality of the Login Information, and you will be
                    responsible for all uses of the Login Information, including
                    transactions, whether or not authorized by you. You are
                    responsible for anything that happens through your Account.
                  </p>
                  <p>
                    AxBet reserves the right to remove or reclaim any usernames
                    at any time and for any reason, including but not limited to
                    claims by a third party that a username violates the third
                    party's rights.
                  </p>
                  <h3>Use Limitations</h3>
                  <p>
                    Any use of the Service in violation of these Use Limitations
                    is strictly prohibited, You agree that you will not, under
                    any circumstances:
                    <ul>
                      <li>
                        {" "}
                        Use or take part (directly or indirectly) in the use of
                        cheats, exploits, automation software, emulators, bots,
                        hacks, mods or any unauthorized third-party software
                        designed to modify or interfere with the Service, any
                        games available on AxBet.
                      </li>
                      <li>
                        Disrupt, interfere with or otherwise adversely affect
                        the normal flow of the Service or otherwise act in a
                        manner that may negatively affect other users'
                        experience when using the Service. This includes win
                        trading and any other kind of manipulation of gaming, or
                        involvement in activity to gain an unfair edge over
                        other players and any other act that intentionally
                        abuses or goes against the design of the Service.
                      </li>
                      <li>
                        Disrupt, overburden, or aid or assist in the disruption
                        or overburdening of any computer or server used to offer
                        or support the Service or any game environment.
                      </li>
                      <li>
                        Post any information on our social media handles that is
                        abusive, threatening, obscene, defamatory, libelous, or
                        racially, or otherwise objectionable or offensive or
                        engage in ongoing toxic behavior, such as by repeatedly
                        posting information on an unsolicited basis.
                      </li>
                      <li>
                        Attempt to, or harass, abuse, or harm, or advocate or
                        incite harassment, abuse, or harm of another person,
                        group, including AxBet employees, including AxBet
                        customer service representatives
                      </li>
                    </ul>
                  </p>
                  <p>
                    AxBet reserves the right to determine what conduct it
                    considers to be in violation of the rules of use or
                    otherwise outside the intent or spirit of these Terms of
                    Service or the Service itself. AxBet reserves the right to
                    take action as a result, which may include terminating your
                    Account and prohibiting you from using the Service in whole
                    or in part.
                  </p>
                  <h3>Suspension and Termination of Account and Service</h3>
                  <p>
                    WITHOUT LIMITING ANY OTHER REMEDIES, AxBet MAY LIMIT,
                    SUSPEND, TERMINATE, MODIFY, OR DELETE ACCOUNTS OR ACCESS TO
                    THE SERVICE OR PORTIONS THEREOF WITH OR WITHOUT NOTICE TO
                    YOU
                    <ul>
                      <li>
                        (i) IF YOU ARE, OR AxBet SUSPECTS THAT YOU ARE, FAILING
                        TO COMPLY WITH THESE TERMS OF SERVICE; OR{" "}
                      </li>
                      <li>
                        (ii) FOR ANY ACTUAL OR SUSPECTED ILLEGAL OR IMPROPER USE
                        OF THE SERVICE
                      </li>
                    </ul>
                  </p>
                  <p>
                    YOU CAN LOSE YOUR USERNAME AS WELL AS ANY BENEFITS,
                    PRIVILEGES, EARNED COINS AND PURCHASED CASH ASSOCIATED WITH
                    YOUR USE OF THE SERVICE, AND AxBet IS UNDER NO OBLIGATION TO
                    COMPENSATE YOU FOR ANY SUCH LOSSES.
                  </p>
                  <p>
                    AxBet reserves the right to stop offering and/or supporting
                    the Service or a particular game or part of the Service at
                    any time, at which point your right to use the Service or a
                    part thereof will be automatically terminated. In such an
                    event, AxBet shall not be required to provide refunds,
                    benefits or other compensation to users in connection with
                    such discontinued Service.
                  </p>
                  <p>
                    By entering into these Terms of Service, you hereby provide
                    your irrevocable consent and agree that you will abide by
                    all the terms and conditions outlined by AxBet
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="0">
                <Accordion.Header>General</Accordion.Header>
                <Accordion.Body>
                  <h3>General FAQ :</h3>
                  <p>
                    <b>Can I change or update my personal information?</b>
                  </p>
                  <p className="mb-3">
                    No, You can’t edit or change your personal information.
                  </p>
                  <p>
                    <b>How can I change my password?</b>
                  </p>
                  <p className="mb-3">
                    You may change your password by going to "Change Password"
                    under My Account. Fill up the required fields. Once your
                    password has been successfully changed, your old password
                    will be rendered invalid.
                  </p>

                  <p>
                    <b>Can I have more than one account?</b>
                  </p>
                  <p className="mb-3">
                    The rules of our site prohibit multiple accounts. We only
                    allow one account per person.
                  </p>

                  <p>
                    <b>
                      I tried to login many times but still received the error
                      message, "Wrong username/password".
                    </b>
                  </p>
                  <p className="mb-3">
                    This could be due to CAPS lock and unnecessary spaces in
                    username or password fields. Hence it’s important to save
                    your username and password or you can login through our app.
                  </p>

                  <p>
                    <b>Is my personal account information secure in AxBet?</b>
                  </p>
                  <p className="mb-3">
                    We will not disclose your personally identifiable
                    information to any person or company. So your data is
                    entirely safe with us.
                  </p>

                  <p>
                    <b>How do I know if the Live game is really Live?</b>
                  </p>
                  <p className="mb-3">
                    Live Dealer studios provide a Live TV or available Live
                    chat.
                  </p>

                  <p>
                    <b>Are Live Dealer games about skill or luck?</b>
                  </p>
                  <p className="mb-3">
                    Live Dealer casino games have both, a degree of skill and
                    luck.
                  </p>

                  <p>
                    <b>Can I Win Playing Casino Games?</b>
                  </p>
                  <p className="mb-3">
                    Yes, you can. The random nature of casino games gives you
                    every chance of overcoming the odds; you essentially just
                    need to get lucky
                  </p>

                  <p>
                    <b>Are Casino Games All About Luck?</b>
                  </p>
                  <p className="mb-3">
                    All casino games are primarily based on luck, and many games
                    are entirely based on luck. In some games, however, there is
                    also an element of strategy involved.
                  </p>

                  <p>
                    <b>Why Do People Play Casino Games?</b>
                  </p>
                  <p className="mb-3">
                    Some people assume that playing casino games is foolish,
                    because of the fact that you are so likely to eventually
                    lose money. However, this ignores the fact that they can be
                    a lot of fun. Many players view them as a form of
                    entertainment, where the chance of winning money simply adds
                    to the excitement. Any money lost is essentially considered
                    as the cost of the entertainment.
                  </p>

                  <p>
                    <b>Is Casino Gambling Expensive?</b>
                  </p>
                  <p className="mb-3">
                    You can spend as much or as little as you want to really,
                    and you certainly don’t need hundreds or thousands of
                    Rupees. The minimum bet at AxBet is fairly low (ZAR 8), so
                    you can play with whatever amounts you feel comfortable
                    with. As long as you set a budget, and stop when you have
                    spent it, you basically have complete control of how much
                    you spend.
                  </p>

                  <p>
                    <b>Can I Get Addicted to Casino Games?</b>
                  </p>
                  <p className="mb-3">
                    Some people would have you believe that if you start
                    gambling, of any kind, you are destined to end up getting
                    addicted and losing all your money. This is simply not true.
                    Casino games are by no means a certainty that you will get
                    addicted. It is important that you are aware of the risks
                    though, and that you gamble responsibly.
                  </p>

                  <p>
                    <b>Are Online Casinos Legal?</b>
                  </p>
                  <p className="mb-3">
                    AxBet operates entirely within the confines of the law. So
                    it’s fair to say you are on a trusted and authorized
                    website.
                  </p>

                  <p>
                    <b>Do Online Casinos Offer Fair Games?</b>
                  </p>
                  <p className="mb-3">
                    Generally speaking, yes they do. The majority of online
                    casinos provide games that are completely fair, using random
                    number generators (RNGs) to produce the results. These RNGs
                    are computer programs that ensure the outcomes of games are
                    random, which means that players can expect similar results
                    to the ones they would see in a live casino.
                  </p>

                  <p>
                    <b>What Are Live Dealer Games?</b>
                  </p>
                  <p className="mb-3">
                    Live dealer games offer a very different experience at
                    online casinos – one that is almost like playing at a land
                    based venue. They feature real life dealers at real tables,
                    with all the action beamed directly to your mobile screen
                    via a live video feed. You play the games in the usual way,
                    with the dealers acting on your instructions accordingly.
                  </p>

                  <p>
                    <b>Can I Play Casino Games From a Mobile Device?</b>
                  </p>
                  <p className="mb-3">
                    Yes, you can. AxBet have all games that are compatible with
                    mobile devices, and have specific apps that you can download
                    to your device. We typically offer a decent selection of
                    different games.
                  </p>

                  <p>
                    <b>What are Casino Bonuses?</b>
                  </p>
                  <p className="mb-3">
                    There are several different types of casino bonuses, and
                    they are basically extra chips that are added to your online
                    account in exchange for making a deposit, or as a reward for
                    your playing activity.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Gaming :</Accordion.Header>
                <Accordion.Body>
                  <p>
                    <b>What are your available game types?</b>
                  </p>
                  <p className="mb-3">
                    <ul>
                      <li>RNG</li>
                      <li>Slots</li>
                      <li>Table and Card</li>
                      <li>Live Games</li>
                    </ul>
                  </p>

                  <p>
                    <b>What are the differences between these game types?</b>
                  </p>
                  <p className="mb-3">
                    <h5>RNG</h5>RNG or Random Number Generated gameplay is
                    played against the game. The results are determined by a
                    game's math and algorithm.
                  </p>

                  <p className="mb-3">
                    <h5>SLOTS</h5>Slots is a game type that uses paylines or
                    winlines combination for an outcome. Being an RNG game, its
                    gameplay is player against the game, and results are
                    determined by a game's math and algorithm.
                  </p>

                  <p className="mb-3">
                    <h5>TABLE & CARD GAMES</h5>Both Table and Card, unlike
                    Slots, do not display reels and paylines and results use
                    different bases. The gameplay requires tables, cards, or
                    other gaming devices such as dice and wheels which are dealt
                    by someone. They are available in both RNG and Live modes.
                  </p>

                  <p className="mb-3">
                    <h5>LIVE GAMES</h5>Live Games are hosted from physical
                    studios. Majority of our tables have a live dealer
                    interacting with the participants. Compared to other
                    providers, Playtech Live games also offer a hybrid type
                    called - Live Slots.
                  </p>

                  <p>
                    <b>
                      I won but the games didn’t give me the winning amount.
                    </b>
                  </p>
                  <p>
                    There’s no way that you will not receive the winning amount.
                    This could be due to poor internet connection as a visual
                    glitch where you didn’t win or lose but your bet was not
                    placed.
                  </p>
                  <p>
                    If you are certain about winning, you can always reach us
                    with a video proof to support your claim. Based on that we
                    can check and assist you.
                  </p>

                  <p>
                    <b>How do I report any errors and bugs?</b>
                  </p>
                  <p>
                    Our team is always working to find and fix any error that
                    might occur in the game. However if you come across any such
                    issues, feel free to contact us through the chat support.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Bonus :</Accordion.Header>
                <Accordion.Body>
                  <p>We provide multiple bonuses to our players.</p>
                  <p>
                    <b>Deposit Bonus-</b>
                  </p>
                  <p className="mb-3">
                    <ul>
                      <li>- Every deposit = 10% </li>
                      <li>- Any deposit on Friday = 15% </li>
                      <li>- Any deposit of 25000 and above = 12%</li>
                      <li> - Any deposit of 50000 and above = 15%</li>
                      <li> - Any deposit of 75000 and above = 18%</li>
                      <li> - Any deposit of 1 lac and above = 20%</li>
                    </ul>
                  </p>

                  <p>
                    <b>Losing Bonus</b>
                  </p>
                  <p className="mb-3">
                    We calculate losing bonus on every Monday and reward the
                    players with a 10% flat losing bonus on every Monday.
                    Whatever amount players lose in a week, they get 10% of that
                    on the following Monday.
                  </p>

                  <p>
                    <b>BONUS COINS</b>
                  </p>
                  <p className="mb-3">
                    Promotional coins as bonus can be used for both Live and RNG
                    modes of Table and Card games. Depending on the promotion,
                    such bonus gets converted to cash upon use for bet and won.
                    Please note- The bonus amount will be deducted from the
                    winning amount and only an extra winning amount will be
                    added towards your main balance. For ex
                  </p>
                  <p>If you used 100 Bonus coins to bet with and won 300.</p>
                  <p>
                    Only 200 will be added towards your main balance as the
                    system takes out the one time bonus coins.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Deposit :</Accordion.Header>
                <Accordion.Body>
                  <p>
                    AxBet is India’s first site to provide a fully automated
                    Deposit and Withdrawal system. Once you sign up or sign in,
                    you can go to the deposit option available on top right and
                    then select the preferred payment mode.
                  </p>

                  <p>
                    <b>If you select UPI- </b>
                  </p>
                  <p>
                    Once you transfer the money, take a screenshot of the same
                    and attach it on the site along with the UTR number and the
                    amount you have transferred.
                  </p>
                  <p>
                    Once you submit your request, System will take about a
                    minute to verify the same and your request will be added.
                  </p>

                  <img src={DepositImg} />
                  <p>
                    <b>If you select a bank - </b>
                  </p>
                  <p>
                    - you can see our bank account details and copy option. You
                    can copy the details and transfer the amount. Then attach
                    the screenshot of the payment and TXN ID and the amount.
                    Click submit.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>Withdrawal :</Accordion.Header>
                <Accordion.Body>
                  <p>
                    Click on your account at the right top corner and select
                    withdraw
                    <ul>
                      <li>- Update your bank account details correctly</li>
                      <li>- Enter amount</li>
                      <li>- Submit the request</li>
                    </ul>
                  </p>
                  <img src={WithdrawImg} />{" "}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </Container>
      </main>

      <div>{isAuth ? <Footer /> : <BeforeLoginFooter />}</div>
    </div>
  );
};

export default Faq;
