import React, { useEffect, useLayoutEffect, useRef } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
import { getBanners } from "../../../../redux/app/actions";
import { useDispatch } from "react-redux";
import { useState } from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Header from "../../../../containers/Header";
import Footer from "../../../../containers/Footer";
import MainSlider from "../../../../containers/MainSlider";

import { casinoTabs } from "../../../../lib/data";
import ImageCard from "../../../components/imageCard";
import { AiOutlineSearch } from "react-icons/ai";
import { BsFillCloudFill } from "react-icons/bs";
import SliderSection from "../../../components/sliderSection";
import { RxCross1 } from "react-icons/rx";
import { gsap } from "gsap";

import EzugiProvider from "../../../../assets/images/providers/Ezugiprovider.png";
import EvolutionProvider from "../../../../assets/images/providers/EvolutionProvider.png";
import VivoProvider from "../../../../assets/images/providers/Vivoprovider.png";
import XPGProvider from "../../../../assets/images/providers/XPGprovi.png";
import SNProvider from "../../../../assets/images/providers/Supernowaprovider.png";
import Slider from "react-slick";

const Casino = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const container = useRef();
  const tl = useRef();
  const { banners } = useSelector((state) => state.app);
  const [isSearched, setIsSearched] = useState(null);
  const [searchedText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(null);
  const [activeCategory, setActiveCategory] = useState("Roulette");
  const categoriesTab = [
    "",
    "Roulette",
    "Blackjack",
    "Baccarat",
    "Poker",
    "Spribe",
    "Vivo",
    "Supernowa",
    // "PragmaticPlay",
    "TV Games",
    "All Games",
  ];

  const [showSlider, setShowSlider] = useState({
    show: true,
    category: "Roulette",
    showMultipleSlides: {
      Roulette: true,
      Blackjack: true,
      Baccarat: true,
      Poker: true,
      Spribe: true,
      Supernowa: true,
      Vivo: true,

      // "Pragmatic Play": "true",
      "TV Games": true,
      "All Games": true,
    },
  });

  const [sliderPageNumber, setSliderPageNumber] = useState({
    Roulette: 1,
    Blackjack: 1,
    Baccarat: 1,
    Poker: 1,
    Spribe: 1,
    Supernowa: 1,
    Vivo: 1,
    // PragmaticPlay: 1,
    "TV Games": 1,
    "All Games": 1,
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBanners());
  }, []);
  const handleSearch = (e) => {
    setSearchText(e?.target?.value);
    const value = e?.target?.value?.toLowerCase();

    if (value) {
      setIsSearched(true);
      let filteredValues = [];

      Object.values(casinoTabs).forEach((slide) => {
        if (slide.name !== "All Games") {
          Object.values(slide).map((item, index) => {
            if (typeof item !== "string") {
              let response = Object.values(item).filter(
                (f) =>
                  f?.name
                    ?.toLowerCase()
                    .trim()
                    ?.indexOf(value.toLowerCase().trim()) > -1
              );
              let clone = [...filteredValues];
              clone = [...clone, ...response];
              filteredValues = clone;
            }
          });
        }
      });

      setFilteredData(filteredValues);
    } else {
      setIsSearched(false);
    }
  };
  const settings = {
    dots: false,
    arrows: true,
    autoplay: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const settings1 = {
    dots: false,
    arrows: true,
    autoplay: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  useLayoutEffect(() => {
    const ctx = gsap.context((self) => {
      const boxes = self.selector(".dot");
      tl.current = gsap
        .timeline()
        .to(boxes[0], { x: 250 })

        .reverse();
    }, container); // <- Scope!
    return () => ctx.revert(); // <- Cleanup!
  }, []);

  const toggleTimeline = () => {
    tl.current.reversed(!tl.current.reversed());
  };
  return (
    <div>
      <div>
        {" "}
        {isAuth ? (
          <HeaderAfterLogin toggleDefault={false} />
        ) : (
          <Header Casino={true} />
        )}
      </div>
      <main className="main GamesSection SlotsSectionNew onlycasinogame">
        <div className="mainBanner">
          <MainSlider banners={banners} />
        </div>

        <div className="provideradded-section d-none">
          <Slider {...settings1}>
            <div className="provider-imagess">
              <a href="/casino/ezugi" className="provider-img">
                <img src={EzugiProvider} alt="Ezugi Provider" />
              </a>
            </div>
            <div className="provider-imagess">
              <a href="/casino/evolution" className="provider-img">
                <img src={EvolutionProvider} alt="Ezugi Provider" />
              </a>
            </div>
            <div className="provider-imagess">
              <a href="/casino/vivo" className="provider-img">
                <img src={VivoProvider} alt="Ezugi Provider" />
              </a>
            </div>
            <div className="provider-imagess">
              <a href="/casino/xpg" className="provider-img">
                <img src={XPGProvider} alt="Ezugi Provider" />
              </a>
            </div>

            <div className="provider-imagess">
              <a href="/casino/supernowa" className="provider-img">
                <img src={SNProvider} alt="Ezugi Provider" />
              </a>
            </div>
          </Slider>
        </div>

        <div className=" containerCss">
          <div className="casino-category filters">
            <ul>
              <div className="position-relative new-search" ref={container}>
                <input
                  id="search-btn"
                  type="checkbox"
                  onClick={toggleTimeline}
                />
                <label for="search-btn">
                  <AiOutlineSearch className="lens" />
                  <div className="dot">
                    <RxCross1
                      className="cross dot"
                      onClick={() => {
                        setSearchText("");
                        setIsSearched(false);
                      }}
                    />
                  </div>
                </label>
                <input
                  id="search-bar"
                  type="text"
                  placeholder="Search..."
                  value={searchedText}
                  onChange={handleSearch}
                />

                <div class="search d-none">
                  <input
                    type="text"
                    placeholder="Search"
                    onChange={handleSearch}
                  />
                  <div class="symbol">
                    <AiOutlineSearch className="lens" />
                  </div>
                </div>
              </div>
              {categoriesTab.map((tab, index) => (
                <li
                  key={index}
                  onClick={() => {
                    setShowSlider((p) => ({
                      category: tab,
                      show: true,
                      ...p,
                    }));
                    setActiveCategory(tab);
                  }}
                >
                  <a
                    href="#"
                    style={
                      activeCategory === tab
                        ? {
                            color: "#ffb900",
                          }
                        : {}
                    }
                  >
                    {tab}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <SliderSection
          isSearched={isSearched}
          activeCategory={activeCategory}
          data={casinoTabs}
          sliderPageNumber={sliderPageNumber}
          isAuth={isAuth}
          showSlider={showSlider}
          filteredData={filteredData}
          setSliderPageNumber={setSliderPageNumber}
          setShowSlider={setShowSlider}
        />
      </main>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Casino;
