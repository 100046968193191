import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { loginUser } from "../../../redux/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Button, Dropdown, Form } from "react-bootstrap";
import hide from "../../../assets/svg/hide.png";
// import LoginLeftPart from "../../../assets/images/modal.gif";
import EyeIcon from "../../../assets/svg/EyeIcon";
import { APP_CONST } from "../../../config/const";

const LoginModal = ({ show, handleClose, handleModalShow }) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const { appDetails } = useSelector((state) => state.app);

  const validateData = () => {
    const { username, password } = formData;
    const newErrors = {};
    if (!username || username === "") {
      newErrors.username = "Username cannot be empty";
    }
    if (!password || password === "") {
      newErrors.password = "Password cannot be empty";
    }
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errorData = validateData();
    if (Object.keys(errorData).length > 0) {
      setErrors(errorData);
    } else {
      let loginData = { ...formData };
      handleClose();
      dispatch(loginUser(loginData));
    }
  };

  const handleChange = (e) => {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;
    let tempFormData = { ...formData, [fieldName]: fieldValue };
    setFormData(tempFormData);
  };
  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={"steps-canvas login-modal"}
      onHide={() => {
        console.log("working");
      }}
      placement={"end"}
    >
      <Modal.Header
        closeButton
        onClick={() => handleClose("crossButton")}
        closeVariant="black"
      >
        <Modal.Title>Log In</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="login-section">
          <div className="ModalSection">
            <div className="Modal-right-part">
              <img src={appDetails?.LOGO_URL} alt="Logo" />
              <div className="Login-rightPart">
                {/* <Modal.Title>Sign in now!</Modal.Title> */}
                <div className="form-steps">
                  {/* <p>Already have an account?</p>
                  <h3>Cool, just login.</h3> */}

                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <label>UserName</label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Username*"
                        name="username"
                        onChange={handleChange}
                        isInvalid={!!errors.username}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.username}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      className="mb-3 position-relative"
                      controlId="formBasicPassword"
                    >
                      <label>Password</label>
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        placeholder="Password*"
                        name="password"
                        onChange={handleChange}
                        isInvalid={!!errors.password}
                      />
                      <span
                        className="icon"
                        onClick={() => setShowPassword((s) => !s)}
                      >
                        {showPassword ? <img src={hide} alt="" /> : <EyeIcon />}
                      </span>
                      <Form.Control.Feedback type="invalid">
                        {errors?.password}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <div className="">
                      <div className="sign-up ForgotPassword d-flex d-flex align-items-center">
                        {/* <a
                          className="form-link"
                          onClick={() => {
                            handleClose();
                            handleModalShow(
                              APP_CONST.AUTH_MODAL.FORGOTO_PASSWORD
                            );
                          }}
                        >
                          Forgot password? <span>Recover it</span>
                        </a> */}
                      </div>
                    </div>
                    <Button
                      variant="primary"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Log In
                    </Button>
                    <div className="LoginUser">
                      <a
                        href="#"
                        onClick={() => {
                          handleClose();
                          handleModalShow(APP_CONST.AUTH_MODAL.MOBILE_NUMBER);
                        }}
                      >
                        Don't have an account? <span>Create it now</span>
                      </a>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default LoginModal;
