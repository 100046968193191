import React, { useEffect, useState } from "react";
import { Button, Offcanvas, Modal, Nav, Navbar } from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import ProfileIcon from "../assets/svg/ProfileIcon";
import TelegramIcon from "../assets/svg/TelegramIcon";
import WalletIcon from "../assets/svg/WalletIcon";
import WhatsappIcon from "../assets/svg/WhatsappIcon";
import BetIcon from "../assets/svg/BetIcon";
import SettingIcon from "../assets/svg/SettingIcon";
import StatementIcon from "../assets/svg/StatementIcon";
import LogoutIcon from "../assets/svg/LogoutIcon";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/auth/actions";
import logo from "../assets/svg/logo_new.png";
import CashierIcon from "../assets/svg/CashierIcon";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import Exchange from "../assets/images/Exchange.png";
import SportsIcon from "../assets/svg/SportsIcon";
import numberWithCommas from "../utils/numbersWithComma";
import MoonLight from "../assets/images/moon.svg";
import SunLight from "../assets/images/sunny.svg";
import LogoLightTheme from "../assets/images/LogoLightTheme.png";
import LogoDarkTheme from "../assets/images/LogoDarkTheme.png";
import { IoWalletOutline } from "react-icons/io5";
import ExchangeFundsFormPopUp from "../views/components/ExchangeFundsFormPopUp";
import { CasinoProviders } from "../lib/data";
// import Index from "../views/pages/privatePages/exchangepopup";
import { getExposureAmount } from "../redux/auth/actions";
import { getBetMatches, getUserBet } from "../redux/sports/actions";

import BotHeadIcon1 from "../assets/images/icons/BotHeadIcon1.png";
import BotHeadIcon2 from "../assets/images/icons/BotHeadIcon2.png";
import BotHeadIcon3 from "../assets/images/icons/BotHeadIcon3.png";
import BotHeadIcon4 from "../assets/images/icons/BotHeadIcon4.png";
import BotHeadIcon5 from "../assets/images/icons/BotHeadIcon5.png";
import BotHeadIcon6 from "../assets/images/icons/BotHeadIcon6.png";
import BotHeadIcon7 from "../assets/images/icons/BotHeadIcon7.png";

import Casino1 from "../assets/images/games-icon/Ezugi1.gif";
import FAQ from "../assets/images/games-icon/language.gif";
import Sports1 from "../assets/images/games-icon/evolution-icon.png";
import Slots1 from "../assets/images/games-icon/Slots1.gif";
import Evolution from "../assets/images/games-icon/evolution1.gif";

import CricketIcon from "../assets/images/games-icon/cricket.gif";

import AppDownload from "../assets/images/icons/download.png";
import bonus from "../assets/images/bonus.png";
import Refferal from "../assets/images/Refferal.png";

import MenuIcon from "../assets/images/icons/header/searchmenu.png";
import LeftBarSports from "../views/components/LeftBarSports";

import AviatorIcon from "../assets/images/icons/header/Aviator.png";
import LuckyNumberIcon from "../assets/images/icons/header/luckynumber.png";
import VegasGameIcon from "../assets/images/icons/header/VEGASNumber.png";
import HorseRacingIcon from "../assets/images/icons/header/horseRacing.png";
import InstantGameIcon from "../assets/images/icons/header/InstantGame.png";
import LiveSportsIcon from "../assets/images/icons/header/LiveSports.png";
import SportsIcon1 from "../assets/images/icons/header/Sports.png";
import BetGamesIcon from "../assets/images/icons/header/BetGame.png";
import GreyHoundsIcon from "../assets/images/icons/header/Greyhound.png";
import GamesSearchModal from "../views/components/GamesSearchModal";

const HeaderAfterLogin = () => {
  const { user, wallet, exposureAmount } = useSelector((state) => state.auth);
  const { betMatches, userBets } = useSelector((state) => state.sports);
  // const betMatches = [
  //   {
  //     exposureAmount: 6438.5,
  //     matchName: "Australia v West Indies",
  //     refMatchId: 10,
  //     totalBets: 2,
  //   },
  // ];
  // const userBets = [
  //   {
  //     odds: 130,
  //     stack: 50,
  //     selectionType: "back",
  //     status: "open",
  //     createdAt: "2022-12-02T03:07:13.000Z",
  //   },
  //   {
  //     odds: 1.23,
  //     stack: 50,
  //     selectionType: "lay",
  //     status: "open",
  //     createdAt: "2022-12-02T03:07:04.000Z",
  //   },
  // ];
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleShow1 = () => setShow1(!show1);
  const dispatch = useDispatch();
  const { appDetails } = useSelector((state) => state.app);
  const [showexchangeModal, setShowexchangeModal] = useState(false);
  const [showBetModal, setShowBetModal] = useState(false);
  const [showUserBetModal, setShowUserBetModal] = useState(false);
  const isApk = localStorage.getItem("isApk") === "true";
  const handleCloseModal = () => setShowexchangeModal(false);
  const handleShowModal = () => setShowexchangeModal(true);
  // function handelSubmit() {
  //   return <Index />;
  // }
  const [showToggle, setShowToggle] = useState(true);
  useEffect(() => {
    if (showToggle) {
      document.body.classList.add("header_open");
    } else {
      document.body.classList.remove("header_open");
    }
  }, [showToggle]);
  useEffect(() => {
    // dispatch(getExposureAmount());
    // dispatch(getBetMatches({ status: "pending" }));
    setShowToggle(window.screen.width > 992);
    return () => {};
  }, []);

  const handleRowClick = (item) => {
    dispatch(
      getUserBet({
        skip: 0,
        pageSize: 10,
        refmatchid: item?.refMatchId,
      })
    );
    setShowBetModal(false);
    setShowUserBetModal(true);
  };

  const [showLightModeToggle, setShowLightModeToggle] = useState(false);
  useEffect(() => {
    if (showLightModeToggle) {
      document.body.classList.add("light-mode");
    } else {
      document.body.classList.remove("light-mode");
    }
  }, [showLightModeToggle]);

  const [showLeftbarToggle, setShowLeftbarToggle] = useState(false);
  useEffect(() => {
    if (showLeftbarToggle) {
      document.body.classList.add("leftbat-opened");
    } else {
      document.body.classList.remove("leftbat-opened");
    }
  }, [showLeftbarToggle]);

  return (
    <>
      <header className="header aftrlgn">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-between align-items-center headerSection pb-1">
                <div className="d-flex align-items-center">
                  <a
                    onClick={handleShow1}
                    className="d-block d-md-none menuicons"
                  >
                    <img src={MenuIcon} alt="User Menu Icon" width={25} />
                  </a>
                  <div className="logo">
                    <a href="/home">
                      <img src={appDetails?.LOGO_URL} alt="Logo" />
                    </a>
                  </div>
                </div>

                <div className="headerRight">
                  <div>
                    <GamesSearchModal />
                  </div>
                  <Dropdown align="end" className="me-2">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      Bal: <span>{numberWithCommas(wallet?.cash || 0)}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="tel:{user?.mobileno}">
                        <b>{user?.mobileno}</b>
                      </Dropdown.Item>
                      <Dropdown.Item href="javascript:void(0)">
                        <h6>Balance</h6>
                        <span>ZAR {numberWithCommas(wallet?.cash || 0)}</span>
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="javascript:void(0)"
                        onClick={() => setShowBetModal(true)}
                      >
                        <h6>Exposure</h6>
                        <span>ZAR {numberWithCommas(exposureAmount || 0)}</span>
                      </Dropdown.Item>
                      <Dropdown.Item href="javascript:void(0)">
                        <h6>Bonus</h6>
                        <span>ZAR {numberWithCommas(wallet?.coins)}</span>
                      </Dropdown.Item>
                      {/* <Dropdown.Item href="/gatewaylist" className="deposit">
                        Deposit
                      </Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                  {/* {(!isApk && (
                    <a
                      href="https://dl.dropbox.com/scl/fi/5mhty4q0r3j3quhpg289j/app-debug_25Aug_11_23Am_betguru.apk?rlkey=knks88iq7xz2bk31lf8cm7sam&dl=0"
                      download="https://dl.dropbox.com/scl/fi/5mhty4q0r3j3quhpg289j/app-debug_25Aug_11_23Am_betguru.apk?rlkey=knks88iq7xz2bk31lf8cm7sam&dl=0"
                      className=" btn btn-primary appdownload"
                      target="__blank"
                    >
                      App <img src={AppDownload} />
                    </a>
                  )) ||
                    null} */}

                  <div className="funds me-2">
                    <ul>
                      {/* <li>
                      <div onClick={() => setShowBetModal(true)}>
                        <div>
                          Exp:{" "}
                          <span>{numberWithCommas(exposureAmount || 0)}</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <a href="javascript:void(0)">
                        Bal: <span>{numberWithCommas(wallet?.cash || 0)}</span>
                      </a>
                    </li> */}
                      <li className="d-none d-md-block">
                        <a
                          href="/gatewaylist"
                          className="deposit btn btn-primary"
                        >
                          Deposit
                        </a>
                      </li>
                    </ul>
                  </div>
                  <Button variant="primary menu-btn" onClick={handleShow}>
                    <ProfileIcon />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Offcanvas show={show} onHide={handleClose} placement={"end"}>
          <Offcanvas.Header closeButton="closeButton" closeVariant="white">
            <Offcanvas.Title>
              <div className="d-flex">
                <div className="profile-img">
                  <ProfileIcon />
                </div>
                <div className="user-info">
                  <span>{user?.mstruserid}</span>
                  <a href="tel:{user?.mobileno}">{user?.mobileno}</a>
                </div>
              </div>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="row">
              <div className="col-6">
                <div className="balance mb-1">Main Balance</div>
              </div>
              <div className="col-6">
                <div className="balance-amount mb-1">
                  {numberWithCommas(wallet?.cash || 0)}
                </div>
              </div>
              <div className="col-6">
                <div className="balance mb-3">Bonus</div>
              </div>
              <div className="col-6">
                <div className="balance-amount mb-3">
                  {numberWithCommas(wallet?.coins) || 0}
                </div>
              </div>
              <div
                className="col-6"
                onClick={() => {
                  navigate("/withdraw");
                }}
              >
                <Button variant="primary  w-100">Withdraw</Button>
              </div>
              <div
                className="col-6"
                onClick={() => {
                  navigate("/gatewaylist");
                }}
              >
                <Button variant="primary w-100">Deposit</Button>
              </div>
              {/* <div className="col-4">
              <Button variant="primary w-100" onClick={handleShowModal}>
                Exchange
              </Button>
              <ExchangeFundsFormPopUp
                showexchangeModal={showexchangeModal}
                handleCloseModal={handleCloseModal}
              />
            </div> */}
            </div>
            <ul className="menu-items">
              <li>
                <a href="/profile">
                  <ProfileIcon />
                  <span>Profile</span>
                </a>
              </li>
              {/* <li>
              <a href="/mybets">
                <BetIcon />
                <span>My Bets</span>
              </a>
            </li> */}
              <li>
                <a href="/bonus">
                  {/* <StatementIcon /> */}
                  <img
                    src={bonus}
                    style={{ width: "8%", marginRight: "10px" }}
                  />
                  <span>Bonus</span>
                </a>
              </li>
              <li>
                <a href="/referral">
                  {/* <StatementIcon /> */}
                  <img
                    src={Refferal}
                    style={{ width: "8%", marginRight: "10px" }}
                  />

                  <span>Referral</span>
                </a>
              </li>
              <li>
                <a href="/cashier">
                  <StatementIcon />
                  <span>Account Statement</span>
                </a>
              </li>
              {/* <li>
              <a href="#">
                <SettingIcon />
                <span>Transfer Funds ( For Excahnge)</span>
              </a>
            </li> */}
              {/* <li>
              <a href="/settings">
                <SettingIcon />
                <span>Settings</span>
              </a>
            </li> */}
              <li onClick={() => dispatch(logoutUser())}>
                <a href="/">
                  <LogoutIcon />
                  <span>Sign Out</span>
                </a>
              </li>
            </ul>
            <ul className="social_links">
              <li>
                <a
                  className="panel social-icon watsapp"
                  href={
                    appDetails?.TELEGRAM != ""
                      ? appDetails?.TELEGRAM
                      : "javascript:void(0)"
                  }
                  target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                >
                  <TelegramIcon />
                </a>
              </li>

              <li>
                <a
                  className="panel social-icon watsapp"
                  href={
                    appDetails?.WHATS_APP_URL != ""
                      ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                      : "javascript:void(0)"
                  }
                  target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                >
                  <WhatsappIcon />
                </a>
              </li>
              <li className="theme-btn">
                <buton
                  type="button"
                  onClick={() => setShowLightModeToggle((s) => !s)}
                >
                  <div className="theme-icon">
                    <img
                      src={MoonLight}
                      alt="Moon Icon"
                      className="dark_theme"
                    />
                    <img
                      src={SunLight}
                      alt="Sun Icon"
                      className="light_theme"
                    />
                  </div>
                </buton>
              </li>
            </ul>
          </Offcanvas.Body>
        </Offcanvas>

        <Modal
          className="betslip_popup outer_popup"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showBetModal}
        >
          <Modal.Body>
            {" "}
            <main className="main">
              <div className="container table-responsive">
                <table className="table text-white">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Exposure amount</th>
                      <th scope="col">Match name</th>
                      <th scope="col">Ref match ID</th>
                      <th scope="col">Total bets</th>
                    </tr>
                  </thead>
                  <tbody>
                    {betMatches &&
                      betMatches.length &&
                      betMatches.map((item, index) => {
                        return (
                          <tr
                            onClick={() => {
                              handleRowClick(item);
                            }}
                          >
                            <th scope="row">{index + 1}</th>
                            <td>{item?.exposureAmount}</td>
                            <td>{item?.matchName}</td>
                            <td>{item?.refMatchId}</td>
                            <td>{item?.totalBets}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </main>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowBetModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          className="betslip_popup inner_popup"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showUserBetModal}
        >
          <Modal.Body>
            {" "}
            <main className="main">
              <div className="container table-responsive">
                <table className="table text-white">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Odds</th>
                      <th scope="col">Stack</th>
                      <th scope="col">Selection type</th>
                      <th scope="col">Status</th>
                      <th scope="col">Create date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userBets &&
                      userBets.length &&
                      userBets.map((item, index) => {
                        return (
                          <tr>
                            <th scope="row">{index + 1}</th>
                            <td>{item?.odds}</td>
                            <td>{item?.stack}</td>
                            <td>{item?.selectionType}</td>
                            <td>{item?.status}</td>
                            <td>{item?.createdAt}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </main>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowUserBetModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>

        <div className="bottom_head">
          <Container fluid>
            <Row>
              <Col xs={12} className="px-0">
                <ul>
                  {/* <li>
                  <a href="/casino/worldcasino">
                    <img src={BotHeadIcon1} alt="bottom head link icon" />
                    <span>All Live Games</span>
                  </a>
                </li> */}
                  {/* <li>
                  <a href="/exchange">
                    <img src={BotHeadIcon2} alt="bottom head link icon" />
                    <span>Exchange</span>
                  </a>
                </li> */}
                  <li>
                    <a href="/casino/ezugi">
                      <img src={Casino1} alt="bottom head link icon" />
                      <span>Ezugi Games</span>
                    </a>
                  </li>

                  {/* <li>
                  <a href="/casino/worldcasino">
                    <img src={BotHeadIcon5} alt="bottom head link icon" />
                    <span>From Casino</span>
                  </a>
                </li> */}
                  <li>
                    <a href="Slots">
                      <img src={Slots1} alt="bottom head link icon" />
                      <span>Slots</span>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/evolution">
                      <img src={Evolution} alt="bottom head link icon" />
                      <span>Evolution</span>
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>

        {show1 && <LeftBarSports className="d-block d-md-none" />}
      </header>

      <div className="HeaderBottomNav">
        <ul>
          <li>
            <a href="/casino/spribe/aviator">
              <img src={AviatorIcon} alt="aviator" />
              Aviator
            </a>
          </li>

          <li>
            <a href="/Slots">
              <img src={VegasGameIcon} alt="aviator" />
              Vegas Games
            </a>
          </li>
          <li>
            <a href="/sports">
              <img src={HorseRacingIcon} alt="aviator" />
              Horse Racing
            </a>
          </li>
          <li>
            <a href="/Spribe">
              <img src={InstantGameIcon} alt="aviator" />
              Instant Games
            </a>
          </li>
          <li>
            <a href="/sports">
              {" "}
              <img src={LiveSportsIcon} alt="aviator" />
              Live Sports
            </a>
          </li>
          <li>
            <a href="/sports">
              {" "}
              <img src={SportsIcon1} alt="aviator" /> Sports
            </a>
          </li>

          <li>
            <a href="/sports">
              <img src={GreyHoundsIcon} alt="aviator" />
              GreyHounds
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default HeaderAfterLogin;
