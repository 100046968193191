import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import TelegramIcon from "../assets/svg/TelegramIcon";
import WhatsappIcon from "../assets/svg/WhatsappIcon";
import EyeIcon from "../assets/svg/EyeIcon";
import { useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import Exchange from "../assets/images/Exchange.png";
import SportsIcon from "../assets/svg/SportsIcon";
import CashierIcon from "../assets/svg/CashierIcon";
import Logo from "../assets/images/lgoguru.png";
import BotHeadIcon1 from "../assets/images/icons/BotHeadIcon1.png";
import BotHeadIcon2 from "../assets/images/icons/BotHeadIcon2.png";
import BotHeadIcon3 from "../assets/images/icons/BotHeadIcon3.png";
import BotHeadIcon4 from "../assets/images/icons/BotHeadIcon4.png";
import BotHeadIcon5 from "../assets/images/icons/BotHeadIcon5.png";
import BotHeadIcon6 from "../assets/images/icons/BotHeadIcon6.png";
import BotHeadIcon7 from "../assets/images/icons/BotHeadIcon7.png";
import { useSelector } from "react-redux";

import FAQ from "../assets/images/games-icon/language.gif";
import SportsIcon2 from "../assets/images/games-icon/Evolution.png";
import SupportIcon from "../assets/images/games-icon/Support1.gif";

import Casino1 from "../assets/images/games-icon/Ezugi1.gif";
import Sports1 from "../assets/images/games-icon/evolution-icon.png";
import Slots1 from "../assets/images/games-icon/Slots1.gif";
import Evolution from "../assets/images/games-icon/evolution1.gif";

import AppDownload from "../assets/images/icons/download.png";

import CricketIcon from "../assets/images/games-icon/cricket.gif";

import SearchIcon from "../assets/images/icons/header/search.png";
import HomeIcons from "../assets/images/icons/header/home.png";

import LoginModal from "../views/components/authModals/LoginModal";
import MobileNumberModal from "../views/components/authModals/MobileNumberModal";
import MobileNumberVerifyModal from "../views/components/authModals/MobileNumberVerifyModal";
import RegisterModal from "../views/components/authModals/RegisterModal";
import ForgotPasswords from "../views/components/authModals/ForgotPassword";
import ResetPassword from "../views/components/authModals/ResetPassword";
import { APP_CONST } from "../config/const";

import MenuIcon from "../assets/images/icons/header/searchmenu.png";
import LeftBarSports from "../views/components/LeftBarSports";

import AviatorIcon from "../assets/images/icons/header/Aviator.png";
import LuckyNumberIcon from "../assets/images/icons/header/luckynumber.png";
import VegasGameIcon from "../assets/images/icons/header/VEGASNumber.png";
import HorseRacingIcon from "../assets/images/icons/header/horseRacing.png";
import InstantGameIcon from "../assets/images/icons/header/InstantGame.png";
import LiveSportsIcon from "../assets/images/icons/header/LiveSports.png";
import SportsIcon1 from "../assets/images/icons/header/Sports.png";
import BetGamesIcon from "../assets/images/icons/header/BetGame.png";
import GreyHoundsIcon from "../assets/images/icons/header/Greyhound.png";
import GamesSearchModal from "../views/components/GamesSearchModal";

const Header = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [show1, setShow1] = useState(false);
  const handleShow1 = () => setShow1(!show1);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const isApk = localStorage.getItem("isApk") === "true";

  const { isAuth } = useSelector((state) => state.auth);
  const [showAuthModals, setShowAuthModals] = useState({
    login: false,
    register: false,
    mobileNumber: false,
    verifyNumber: false,
    forgotpassword: false,
    resetpassword: false,
  });

  const handleModalShow = (modalName) => {
    setShowAuthModals((p) => ({ ...p, [modalName]: true }));
  };

  const handleModalClose = (modalName) => {
    setShowAuthModals((p) => ({ ...p, [modalName]: false }));
  };

  return (
    <>
      <header className="header beforeheader">
        <div className="HeaderMenu">
          <ul>
            <li>
              <a href="/">
                <img src={HomeIcons} alt="home icon" />
              </a>
            </li>
            <li>
              <a href="javascript:void(0)">Promotions</a>
            </li>
            <li>
              <a href="javascript:void(0)">How To Videos</a>
            </li>
            <li>
              <a href="javascript:void(0)">Terms & Conditions</a>
            </li>
            <li>
              <a href="javascript:void(0)">Betiting Rules</a>
            </li>
            <li>
              <a href="javascript:void(0)">Fixtures</a>
            </li>
            <li>
              <a href="javascript:void(0)">Branches</a>
            </li>
            <li>
              <a href="javascript:void(0)">Live Scores</a>
            </li>
            <li>
              <a href="javascript:void(0)">Sporting Results</a>
            </li>
            <li>
              <a href="javascript:void(0)">Sporting Statistics</a>
            </li>
          </ul>

          <ul>
            <li>
              <a href="javascript:void(0)">
                15:50:51 GMT <span>+5.5</span>
              </a>
            </li>
          </ul>
        </div>
        <div className="top_head">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <div className="d-flex justify-content-between align-items-center headerSection">
                  <div className="d-flex align-items-center">
                    <a
                      onClick={handleShow1}
                      className="d-block d-md-none menuicons"
                    >
                      <img src={MenuIcon} alt="User Menu Icon" width={25} />
                    </a>
                    <div className="logo">
                      <a href="/landing-page">
                        <img src={appDetails?.LOGO_URL} alt="Logo" />
                      </a>
                    </div>
                  </div>

                  <div className="headerRight">
                    {(!isApk && (
                      <a
                        href="https://dl.dropbox.com/scl/fi/5mhty4q0r3j3quhpg289j/app-debug_25Aug_11_23Am_betguru.apk?rlkey=knks88iq7xz2bk31lf8cm7sam&dl=0"
                        download="https://dl.dropbox.com/scl/fi/5mhty4q0r3j3quhpg289j/app-debug_25Aug_11_23Am_betguru.apk?rlkey=knks88iq7xz2bk31lf8cm7sam&dl=0"
                        className=" btn btn-primary appdownload d-none"
                        target="__blank"
                      >
                        App <img src={AppDownload} />
                      </a>
                    )) ||
                      null}

                    {/* <a href="javascript:void(0)" className="searchIcon">
                      <img src={SearchIcon} alt="searchicon" />
                    </a> */}
                    <GamesSearchModal />
                    <Button
                      variant="primary"
                      // onClick={() => {
                      //   navigate("/sign-in");
                      // }}
                      onClick={() => {
                        handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                      }}
                      className="button-primary btn_signin"
                    >
                      Login
                    </Button>
                    <Button
                      variant="primary"
                      // onClick={() => {
                      //   navigate("/mobile-number");
                      // }}
                      onClick={() => {
                        handleModalShow(APP_CONST.AUTH_MODAL.MOBILE_NUMBER);
                      }}
                      className="button-primary btn_signup ms-2"
                    >
                      Join
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* <Offcanvas
        className={"steps-canvas"}
        show={show}
        onHide={handleClose}
        placement={"end"}
      >
        <Offcanvas.Header closeButton="closeButton" closeVariant="white">
          <Offcanvas.Title>
            <span className="top-heading">Sign in now!</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-steps">
            <h2 className="h2">Hello Again!</h2>
            <p>
              Welcome back, you've
              <br />
              been missed!
            </p>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Control type="text" placeholder="Enter Username*" />
              </Form.Group>
              <Form.Group
                className="mb-3 position-relative"
                controlId="formBasicPassword"
              >
                <Form.Control type="password" placeholder="Password*" />
                <span className="icon">
                  <EyeIcon />
                </span>
              </Form.Group>
              <div className="d-flex justify-content-end mb-3">
                <a className="form-link" href="#">
                  Password Recovery
                </a>
              </div>
              <Button variant="primary" type="submit">
                Sign in
              </Button>
            </Form>
            <div className="sign-up mt-4">
              Not a member?
              <br />
              <a href="register">Sign Up Now</a>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas> */}
        {show1 && <LeftBarSports className="d-block d-md-none" />}
      </header>

      <div className="HeaderBottomNav">
        <ul>
          <li>
            <a
              onClick={() => {
                handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
              }}
            >
              <img src={AviatorIcon} alt="aviator" />
              Aviator
            </a>
          </li>

          <li>
            <a
              onClick={() => {
                handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
              }}
            >
              <img src={VegasGameIcon} alt="aviator" />
              Vegas Games
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
              }}
            >
              <img src={HorseRacingIcon} alt="aviator" />
              Horse Racing
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
              }}
            >
              <img src={InstantGameIcon} alt="aviator" />
              Instant Games
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
              }}
            >
              <img src={LiveSportsIcon} alt="aviator" />
              Live Sports
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
              }}
            >
              <img src={SportsIcon1} alt="aviator" /> Sports
            </a>
          </li>

          <li>
            <a
              onClick={() => {
                handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
              }}
            >
              <img src={GreyHoundsIcon} alt="aviator" />
              GreyHounds
            </a>
          </li>
        </ul>
      </div>

      <LoginModal
        show={showAuthModals.login}
        handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.LOGIN)}
        handleModalShow={handleModalShow}
      />
      {showAuthModals.mobileNumber && (
        <MobileNumberModal
          show={showAuthModals.mobileNumber}
          handleClose={() =>
            handleModalClose(APP_CONST.AUTH_MODAL.MOBILE_NUMBER)
          }
          handleModalShow={handleModalShow}
        />
      )}
      {showAuthModals.verifyNumber && (
        <MobileNumberVerifyModal
          show={showAuthModals.verifyNumber}
          handleClose={() =>
            handleModalClose(APP_CONST.AUTH_MODAL.VERIFY_NUMBER)
          }
          handleModalShow={handleModalShow}
        />
      )}
      {showAuthModals.register && (
        <RegisterModal
          show={showAuthModals.register}
          handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.REGISTER)}
          handleModalShow={handleModalShow}
        />
      )}

      <ForgotPasswords
        show={showAuthModals.forgotpassword}
        handleClose={() =>
          handleModalClose(APP_CONST.AUTH_MODAL.FORGOTO_PASSWORD)
        }
        handleModalShow={handleModalShow}
      />
      <ResetPassword
        show={showAuthModals.resetpassword}
        handleClose={() =>
          handleModalClose(APP_CONST.AUTH_MODAL.RESET_PASSWORD)
        }
        handleModalShow={handleModalShow}
      />
    </>
  );
};

export default Header;
