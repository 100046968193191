import React, { useState } from "react";
import CashierIcon from "../assets/svg/CashierIcon";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import SportsIcon from "../assets/svg/SportsIcon";
import Exchange from "../assets/images/Exchange.png";
import Whatsapp from "../assets/images/Whatsapp.svg";
import Telegram from "../assets/images/Telegram.svg";
// import Facebook from "../assets/images/Facebook.svg";
import Instagram from "../assets/images/Instagram.svg";
import logo from "../assets/svg/logo_new.png";
import RNG from "../assets/images/providers/rng-certified.png";
import Gambling from "../assets/images/providers/gambling-commission.png";
import ResponsibleG from "../assets/images/providers/responsible-gaming.png";
import Gamble18 from "../assets/images/providers/gamble-resp-18.png";
import PaymentMod from "../assets/images/providers/payment-option.png";
import Betfair from "../assets/images/providers/betfair.png";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CasinoProviders } from "../lib/data";

import ProviderImg from "../assets/images/games-icon/validate.png";
import Facebook1 from "../assets/images/games-icon/facebook.png";
import Instagram1 from "../assets/images/games-icon/instagram.png";
import Telegram1 from "../assets/images/games-icon/telegram1.png";

import HomeFoot from "../assets/images/games-icon/logo.png";
import Casino1 from "../assets/images/games-icon/casinoezugi-icon.png";
import Sports1 from "../assets/images/games-icon/Ezugi.png";
import Support from "../assets/images/games-icon/Depositnew.png";
import Deposit from "../assets/images/games-icon/Deposit1.png";
import Evolution from "../assets/images/games-icon/evolution-icon.png";
import CricketIcon from "../assets/images/games-icon/cricket-iconnew.png";

import VisaImg from "../assets/images/icons/footer/visa.png";
import MasterCardImg from "../assets/images/icons/footer/mastercard.png";
import EFTImg from "../assets/images/icons/footer/eft.png";
import BluImg from "../assets/images/icons/footer/blu.png";
import ForYouImg from "../assets/images/icons/footer/1-for-you.png";
import OTTImg from "../assets/images/icons/footer/ott.png";
import OzowImg from "../assets/images/icons/footer/ozow.png";
import ZapperImg from "../assets/images/icons/footer/zapper.png";
import DnWImg from "../assets/images/icons/footer/branch-payments.png";
import CelbuxImg from "../assets/images/icons/footer/celbux-logo.png";
import SnapImg from "../assets/images/icons/footer/snapscan.png";

import FBImg from "../assets/images/icons/footer/facebook.png";
import InstaImg from "../assets/images/icons/footer/instagram.png";
import ModalWrapper from "./ModalWrapper";

const BeforeLoginFooter = (data) => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const { isAuth } = useSelector((state) => state.auth);
  const [showAuthModals, setShowAuthModals] = useState(false);
  const handleGameClick = (redirectionUrl) => {
    if (isAuth) {
      navigate(redirectionUrl);
    } else {
      setShowAuthModals(true);
    }
  };

  return (
    <footer className="footer">
      <div className="FooterSection">
        <div className="FooterPartFirst">
          <h4>ABOUT US</h4>
          <p>
            No persons under the age of 18 years are permitted to gamble.
            Winners know when to stop. National Responsible Gambling Programme:
            1234 567 890 or visit{" "}
            <a href="/responsiblegames">https://responsiblegambling.org.za</a>
          </p>
          <ul>
            <li>Telephone: </li>
            <li>Email: </li>
          </ul>

          <h4>PAYMENT OPTIONS</h4>
          <ul className="Payments">
            <li>
              <img src={VisaImg} alt="visa" />
            </li>
            <li>
              <img src={MasterCardImg} alt="visa" />
            </li>
            <li>
              <img src={EFTImg} alt="visa" />
            </li>
            <li>
              <img src={BluImg} alt="visa" />
            </li>
            <li>
              <img src={ForYouImg} alt="visa" />
            </li>
            <li>
              <img src={OTTImg} alt="visa" />
            </li>
            <li>
              <img src={OzowImg} alt="visa" />
            </li>
            <li>
              <img src={ZapperImg} alt="visa" />
            </li>
            <li>
              <img src={SnapImg} alt="visa" />
            </li>
            <li>
              <img src={DnWImg} alt="visa" />
            </li>
            <li>
              <img src={CelbuxImg} alt="visa" />
            </li>
          </ul>
        </div>

        <div className="FooterPartLast">
          <h4>SOCIAL MEDIA</h4>
          <ul className="socilamedia">
            <li>
              <a
                className="panel"
                href={
                  appDetails?.FACEBOOK != ""
                    ? appDetails?.FACEBOOK
                    : "javascript:void(0)"
                }
                target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
              >
                <img src={FBImg} alt="visa" />
              </a>
            </li>
            <li>
              <a
                className="panel"
                href={
                  appDetails?.INSTATGRAM != ""
                    ? appDetails?.INSTATGRAM
                    : "javascript:void(0)"
                }
                target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
              >
                <img src={InstaImg} alt="visa" />
              </a>
            </li>
          </ul>
        </div>

        <div className="FooterPartLast">
          <h4>INFORMATION</h4>
          <ul>
            <li>
              <div onClick={() => handleGameClick(data.href)}>Contact Us</div>
            </li>
            <li>
              <a href="/privacypolicys ">Privacy Policy</a>
            </li>
            <li>
              <a href="/responsiblegames">Responsible Gaming</a>
            </li>
            <li>
              <a href="/fairplay">Fair Play</a>
            </li>
            <li>
              <a href="/gamesrule">Games Rules</a>
            </li>
            <li>
              <a href="/termss">Terms and Conditions</a>
            </li>
          </ul>
        </div>

        <div className="FooterPartLast">
          <h4>LEGAL</h4>
          <ul>
            <li>
              <div onClick={() => handleGameClick(data.href)}>KYC</div>
            </li>
            <li>
              <a href="/responsiblegames">Responsible Gambling</a>
            </li>
            <li>
              <div onClick={() => handleGameClick(data.href)}>
                Self Exclusion
              </div>
            </li>
            <li>
              <div onClick={() => handleGameClick(data.href)}>Guides</div>
            </li>
          </ul>
        </div>
      </div>

      <div className="FooterLogo">
        <a href="javascript:void(0)">
          <img src={appDetails?.LOGO_URL} alt="Logo" />
        </a>
        <div className="copyContent">
          <p>
            Copyright © 2023 <span>/axbet</span> | All rights reserved.
          </p>
        </div>
      </div>

      <div className="d-none">
        <div className="container">
          <div className="providers_img d-none">
            <a href="javascript:void(0)" style={{ width: "65px" }}>
              <img src={RNG} loading="lazy" alt="RNG Certified" />
            </a>
            <a href="javascript:void(0)" style={{ width: "80px" }}>
              <img src={Gambling} loading="lazy" alt="Gambling Commission" />
            </a>
            <a href="javascript:void(0)" style={{ width: "130px" }}>
              <img src={ResponsibleG} loading="lazy" alt="Responsible Gaming" />
            </a>
            <a href="javascript:void(0)" style={{ width: "120px" }}>
              <img src={Gamble18} loading="lazy" alt="Gamble Responsible" />
            </a>
          </div>

          <div className="helpline d-none">
            <a
              className="panel"
              href={
                appDetails?.WHATS_APP_URL != ""
                  ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                  : "javascript:void(0)"
              }
              target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
            >
              Call Us {appDetails?.WHATS_APP_URL}
            </a>
          </div>

          <div className="footer-links d-none">
            <ul>
              <li>
                <a href="/contactus">Contact Us</a>
              </li>
              <li>
                <a href="/privacypolicys ">Privacy Policy</a>
              </li>
              <li>
                <a href="/responsiblegames">Responsible Gaming</a>
              </li>
              <li>
                <a href="/fairplay">Fair Play</a>
              </li>
              <li>
                <a href="/gamesrule">Games Rules</a>
              </li>
              <li>
                <a href="/termss">Terms and Conditions</a>
              </li>
            </ul>
          </div>

          <div class="d-flex justify-content-center mt-2 d-none">
            <p style={{ color: "rgb(204, 204, 204)" }}>
              Accept, process &amp; disburse digital payments for your business.
            </p>
          </div>
          <div class="d-flex justify-content-center mt-2 d-none">
            <img
              src={PaymentMod}
              loading="lazy"
              style={{ width: "250px" }}
              alt="Payment Otions"
            />
          </div>

          <div class="d-flex justify-content-center mt-3 mb-3 d-none">
            <img
              src={Betfair}
              loading="lazy"
              class="mb-3 betfair"
              alt="Betfair Logo"
            />
          </div>

          <p className="d-none">{appDetails?.FOOTER_TEXT || ""}</p>
          <div className="paymentImages d-none">
            <ul>
              <li>
                <img src="./images/sewa.jpeg" />
              </li>
              <li>
                <img src="./images/khalti.jpeg" />
              </li>
            </ul>
          </div>

          <div className="social-icons d-none">
            <ul>
              <li>
                <a
                  className="panel"
                  href={
                    appDetails?.WHATS_APP_URL != ""
                      ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                      : "javascript:void(0)"
                  }
                  target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                >
                  {/* <span className="notification-count">2</span> */}
                  <img src={Whatsapp} alt="Whatsapp Icon" />
                </a>
              </li>
              <li>
                <a
                  className="panel"
                  href={
                    appDetails?.TELEGRAM != ""
                      ? appDetails?.TELEGRAM
                      : "javascript:void(0)"
                  }
                  target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                >
                  <img src={Telegram} alt="Telegram Icon" />
                </a>
              </li>
              <li>
                <a
                  className="panel"
                  href={
                    appDetails?.FACEBOOK != ""
                      ? appDetails?.FACEBOOK
                      : "javascript:void(0)"
                  }
                  target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                >
                  <img src="/images/facebook-512.svg" alt="Facebook Icon" />
                </a>
              </li>
              <li>
                <a
                  className="panel"
                  href={
                    appDetails?.INSTATGRAM != ""
                      ? appDetails?.INSTATGRAM
                      : "javascript:void(0)"
                  }
                  target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                >
                  <img src={Instagram} alt="Instagram Icon" />
                </a>
              </li>
            </ul>
          </div>

          <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 d-block d-lg-none">
            <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
              <li>
                <a href="/sign-in">
                  <img src={Casino1} alt="Exchange Icon" />
                  <span>Ezugi</span>
                </a>
              </li>

              <li>
                <a href="/sign-in">
                  {/* <CashierIcon /> */}
                  <img src={Evolution} alt="Exchange Icon" />
                  <span>Evolution</span>
                </a>
              </li>

              <li>
                <a className="active" href="/">
                  {/* <HomeIcon /> */}
                  <img src={HomeFoot} />
                  {/* <span>Home</span> */}
                </a>
              </li>
              <li>
                <a href="/sports">
                  {/* <img src={Support} alt="Exchange Icon" /> */}
                  {/* <SportsIcon /> */}
                  <img src={CricketIcon} alt="cricket Icon" />
                  <span>Sports</span>
                </a>
              </li>
              <li>
                <a href="/sign-in">
                  {/* <SportsIcon /> */}
                  <img src={Sports1} alt="Exchange Icon" />
                  <span>Slots</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="container-fluid">
          <div className="mainFooter">
            <div className="footer-content">
              <div className="footer-nav">
                <h3>SOLUTIONS</h3>
                <ul>
                  <li>
                    <a href="/sign-in">Dispute Resolution</a>
                  </li>
                  <li>
                    <a href="/sign-in">Fairness</a>
                  </li>
                  <li>
                    <a href="/sign-in">Payout</a>
                  </li>
                  <li>
                    <a href="/sign-in">Registration</a>
                  </li>
                </ul>
              </div>
              <div className="footer-nav">
                <h3>SUPPORT</h3>
                <ul>
                  <li>
                    <a href="/sign-in">KYC</a>
                  </li>
                  <li>
                    <a href="/responsiblegames">Responsible Gambling</a>
                  </li>
                  <li>
                    <a href="/sign-in">Self Exclusion</a>
                  </li>
                  <li>
                    <a href="/sign-in">Guides</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer-content">
              <div className="footer-nav">
                <h3>COMPANY</h3>
                <ul>
                  <li>
                    <a href="/sign-in">About Us</a>
                  </li>
                  <li>
                    <a href="/contactuss">Contact Us</a>
                  </li>
                  <li className="d-block d-md-none">
                    <a href="/faq">FAQ</a>
                  </li>
                  <li>
                    <a href="/sign-in">Jobs</a>
                  </li>
                  <li>
                    <a href="/sign-in">Partners</a>
                  </li>
                </ul>
              </div>
              <div className="footer-nav">
                <h3>LEGAL</h3>
                <ul>
                  <li>
                    <a href="/sign-in">Claim</a>
                  </li>
                  <li>
                    <a href="/privacypolicys">Privacy</a>
                  </li>
                  <li>
                    <a href="/termss">Terms</a>
                  </li>
                  <li>
                    <a href="/sign-in">Anti Money</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer-content">
              <div className="footer-nav">
                <div className="providers_img">
                  <a href="javascript:void(0)" style={{ width: "65px" }}>
                    <img src={RNG} loading="lazy" alt="RNG Certified" />
                  </a>
                  <a href="javascript:void(0)" style={{ width: "80px" }}>
                    <img
                      src={Gambling}
                      loading="lazy"
                      alt="Gambling Commission"
                    />
                  </a>
                  <a href="javascript:void(0)" style={{ width: "130px" }}>
                    <img
                      src={ResponsibleG}
                      loading="lazy"
                      alt="Responsible Gaming"
                    />
                  </a>
                  <a href="javascript:void(0)" style={{ width: "120px" }}>
                    <img
                      src={Gamble18}
                      loading="lazy"
                      alt="Gamble Responsible"
                    />
                  </a>
                </div>
                <div className="providerImg">
                  <img src={ProviderImg} />
                </div>
              </div>
            </div>
          </div>

          <div className="general-foot-content">
            <p>
              In order to register for this website, the user is required to
              accept the <a href="/termss">General Terms and Conditions</a>. In
              the event the General Terms and Conditions are updated, existing
              users may choose to discontinue using the products and services
              before the said update shall become effective, which is a minimum
              of two weeks after it has been announced.
            </p>
          </div>
          <div className="copy-right-social-icon">
            <div className="copyContent">
              <p>
                Copyright © 2023 <span>AxBet</span> | All rights reserved.
              </p>
            </div>
            <div className="socila-icons">
              <a
                href={
                  appDetails?.FACEBOOK != ""
                    ? appDetails?.FACEBOOK
                    : "javascript:void(0)"
                }
                target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
              >
                <img src={Facebook1} />
              </a>
              <a
                href={
                  appDetails?.INSTATGRAM != ""
                    ? appDetails?.INSTATGRAM
                    : "javascript:void(0)"
                }
                target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
              >
                <img src={Instagram1} />
              </a>
              <a
                href={
                  appDetails?.TELEGRAM != ""
                    ? appDetails?.TELEGRAM
                    : "javascript:void(0)"
                }
                target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
              >
                <img src={Telegram1} />
              </a>
            </div>
          </div>
        </div>
      </div>
      {showAuthModals && (
        <ModalWrapper
          defaultShow={"login"}
          setShowAuthModals={setShowAuthModals}
        />
      )}
    </footer>
  );
};

export default BeforeLoginFooter;
