import React, { useEffect, useLayoutEffect, useState } from "react";
import { APP_CONST } from "../config/const";
import LoginModal from "../views/components/authModals/LoginModal";
import MobileNumberModal from "../views/components/authModals/MobileNumberModal";
import MobileNumberVerifyModal from "../views/components/authModals/MobileNumberVerifyModal";
import RegisterModal from "../views/components/authModals/RegisterModal";
import ForgotPasswords from "../views/components/authModals/ForgotPassword";
import ResetPassword from "../views/components/authModals/ResetPassword";

const ModalWrapper = ({
  defaultShow,
  setShowAuthModals: componentModalState,
}) => {
  const [showAuthModals, setShowAuthModals] = useState({
    login: false,
    register: false,
    mobileNumber: false,
    verifyNumber: false,
    forgotpassword: false,
    resetpassword: false,
  });
  useEffect(() => {
    if (defaultShow && defaultShow in showAuthModals) {
      handleModalShow(defaultShow);
    }
  }, [defaultShow]);
  const handleModalShow = (modalName) => {
    setShowAuthModals((p) => ({ ...p, [modalName]: true }));
  };

  const handleModalClose = (modalName, closeType = "auto") => {
    if (closeType == "auto") {
      setShowAuthModals((p) => ({ ...p, [modalName]: false }));
    } else {
      setShowAuthModals((p) => ({ ...p, [modalName]: false }));
      componentModalState(false);
    }
  };

  return (
    <>
      {showAuthModals.login && (
        <LoginModal
          show={showAuthModals.login}
          handleClose={(closeType) =>
            handleModalClose(APP_CONST.AUTH_MODAL.LOGIN, closeType)
          }
          handleModalShow={handleModalShow}
        />
      )}
      {showAuthModals.mobileNumber && (
        <MobileNumberModal
          show={showAuthModals.mobileNumber}
          handleClose={(closeType) =>
            handleModalClose(APP_CONST.AUTH_MODAL.MOBILE_NUMBER, closeType)
          }
          handleModalShow={handleModalShow}
        />
      )}
      {showAuthModals.verifyNumber && (
        <MobileNumberVerifyModal
          show={showAuthModals.verifyNumber}
          handleClose={(closeType) =>
            handleModalClose(APP_CONST.AUTH_MODAL.VERIFY_NUMBER, closeType)
          }
          handleModalShow={handleModalShow}
        />
      )}
      {showAuthModals.register && (
        <RegisterModal
          show={showAuthModals.register}
          handleClose={(closeType) =>
            handleModalClose(APP_CONST.AUTH_MODAL.REGISTER, closeType)
          }
          handleModalShow={handleModalShow}
        />
      )}

      {showAuthModals.forgotpassword && (
        <ForgotPasswords
          show={showAuthModals.forgotpassword}
          handleClose={(closeType) =>
            handleModalClose(APP_CONST.AUTH_MODAL.FORGOTO_PASSWORD, closeType)
          }
          handleModalShow={handleModalShow}
        />
      )}
      {showAuthModals.resetpassword && (
        <ResetPassword
          show={showAuthModals.resetpassword}
          handleClose={(closeType) =>
            handleModalClose(APP_CONST.AUTH_MODAL.RESET_PASSWORD, closeType)
          }
          handleModalShow={handleModalShow}
        />
      )}
    </>
  );
};
export default ModalWrapper;
