import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { mobileVerification } from "../../../redux/auth/actions";
import { Toaster } from "../../../utils/toaster";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { APP_CONST } from "../../../config/const";

const MobileNumberVerifyModal = ({ show, handleClose, handleModalShow }) => {
  const [isVerifying, setIsVerifying] = useState(false);
  const [otp, setOtpValue] = useState("");
  const { appDetails } = useSelector((state) => state.app);
  const [mobileDetails, setMobileDetails] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getMobileDetails();
  }, []);
  const getMobileDetails = () => {
    const details =
      localStorage.getItem("mobile_details") &&
      JSON.parse(localStorage.getItem("mobile_details"));
    if (details) {
      setMobileDetails(details);
    }
  };

  const handleSubmit = () => {
    setIsVerifying(true);

    dispatch(mobileVerification({ ...mobileDetails, otp }))
      .then((res) => {
        setIsVerifying(false);
        handleClose();
        handleModalShow(APP_CONST.AUTH_MODAL.REGISTER);
        // navigate("/register", {
        //   state: {
        //     referalCode: location?.state?.referalCode,
        //     agentCode: location?.state?.agentCode,
        //   },
        // });
      })
      .catch((err) => {
        setIsVerifying(false);
        Toaster(APP_CONST.TOAST_TYPE.ERROR, err.response.data.message);
      });
  };
  return (
    <Modal
      show={show}
      className={"steps-canvas login-modal"}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {
        console.log("working");
      }}
      placement={"end"}
    >
      <Modal.Header
        closeButton
        onClick={() => handleClose("crossButton")}
        closeVariant="black"
        style={{ color: "#013862" }}
      >
        {/* <Modal.Title>Verify Number</Modal.Title> */}
      </Modal.Header>
      <Modal.Body style={{ background: "#2e393d" }}>
        <div className="login-section">
          <div className="ModalSection">
            <div className="Modal-right-part">
              <img src={appDetails?.LOGO_URL} alt="Logo" />
              <div className="form-steps">
                <h2 className="h2 " style={{ marginBottom: "30px" }}>
                  Verify!
                </h2>
                <h4 style={{ color: "#EFEFEF", marginBottom: "40px" }}>
                  Please enter OTP you received on your mobile number and
                  verify.
                </h4>
                <Form>
                  <Form.Group
                    className="mb-3 position-relative"
                    controlId="formBasicPassword"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Enter OTP*"
                      onChange={(e) => setOtpValue(e.target.value)}
                    />
                    <span className="icon">{/* <EyeIcon /> */}</span>
                  </Form.Group>
                </Form>

                <Button
                  variant="primary"
                  onClick={handleSubmit}
                  disabled={isVerifying}
                  style={{ marginTop: "30px" }}
                >
                  {!isVerifying ? "VERIFY" : "SUBMITTING"}
                </Button>
              </div>
              <div className="LoginUser mt-4">
                <a
                  onClick={() => {
                    handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                  }}
                >
                  {" "}
                  Already have an account? Log In here
                </a>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MobileNumberVerifyModal;
