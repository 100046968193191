import React from "react";
import { QRCodeCanvas } from "qrcode.react";

export default function QRCodeCard({ text }) {
  return (
    <div className=" mx-auto  w-full max-w-[12rem]  relative ">
      <div className="ml-4 ">
        <div className="bg-zinc-50 rounded-lg qrcanvas">
          <QRCodeCanvas
            id="upi-qrcode"
            renderAs="canvas"
            value={text}
            bgColor={"#fafafa"}
          />
        </div>
      </div>
    </div>
  );
}
