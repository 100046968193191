import React from "react";
import Slider from "react-slick";

import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";
import { casinoTabs } from "../../../../lib/data";
import CasinoCard from "../../../components/CasinoCard";

import Spribe1 from "../../../../assets/images/banner/gamebanner/Aviator.jpg";
import Spribe2 from "../../../../assets/images/banner/gamebanner/Mine.jpg";
import Spribe3 from "../../../../assets/images/banner/gamebanner/Hilo.jpg";
import Spribe4 from "../../../../assets/images/banner/gamebanner/Plinko.jpg";

const Spribe = () => {
  const settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <div>
        <HeaderAfterLogin />
      </div>

      <main className="main">
        <div className="GameSlider">
          <Slider {...settings}>
            <div>
              <a href="#">
                <img src={Spribe1} alt="Live casino game" />
                <div className="game_name"></div>
              </a>
            </div>
            <div>
              <a href="#">
                <img src={Spribe2} alt="Live casino game" />
                <div className="game_name"></div>
              </a>
            </div>
            <div>
              <a href="#">
                <img src={Spribe3} alt="Live casino game" />
                <div className="game_name"></div>
              </a>
            </div>
            <div>
              <a href="#">
                <img src={Spribe4} alt="Live casino game" />
                <div className="game_name"></div>
              </a>
            </div>
          </Slider>
        </div>
        <div className="slots-section SlotsHomeSection">
          <div className="games-section">
            <div className="game-heading">
              <span>Instant Games</span>
            </div>
            <div className="SlotsHomeImg">
              <ul>
                <li>
                  {Array.isArray(Object.values(casinoTabs?.Spribe)) &&
                    Object.values(casinoTabs?.Spribe).map((game, idx) => (
                      <CasinoCard key={"casinogame" + idx} data={game} />
                    ))}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>

      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Spribe;
