import React, { useState } from "react";
import { forgotPassword } from "../../../redux/auth/actions";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
// import EyeIcon from "../../../../assets/svg/EyeIcon";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import parsePhoneNumber from "libphonenumber-js";
import { Toaster } from "../../../utils/toaster";
import { APP_CONST } from "../../../config/const";
import { Modal } from "react-bootstrap";

import { useSelector } from "react-redux";

const ForgotPasswords = ({ show, handleClose, handleModalShow }) => {
  const { appDetails } = useSelector((state) => state.app);
  const navigate = useNavigate();
  const [mobileNumber, setMobileNumber] = useState("");
  const handleSubmit = async () => {
    const phoneNumber = parsePhoneNumber("+" + mobileNumber);
    let payload = {};
    if (phoneNumber) {
      payload.phone = phoneNumber.nationalNumber;
      payload.countryCode = phoneNumber.countryCallingCode;
      forgotPassword(payload)
        .then((res) => {
          if (res.data.error === false) {
            Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "OTP sent");
            // navigate("/reset-password");
            handleClose();
            handleModalShow(APP_CONST.AUTH_MODAL.REGISTER);
          }
        })
        .catch((err) => {
          Toaster(APP_CONST.TOAST_TYPE.ERROR, err.response.data.message);
        });
    } else {
      Toaster(APP_CONST.TOAST_TYPE.ERROR, "Please enter mobile number");
    }
  };
  return (
    <Modal
      show={show}
      className={"steps-canvas login-modal"}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {
        console.log("working");
      }}
      placement={"end"}
    >
      <Modal.Header
        closeButton
        onClick={() => handleClose("crossButton")}
        closeVariant="black"
      >
        <Modal.Title>Account Recovery</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="login-section">
          <div className="ModalSection">
            <div className="Modal-right-part">
              <img src={appDetails?.LOGO_URL} alt="Logo" />
              <div className="Login-rightPart">
                {/* <Modal.Title>Sign in now!</Modal.Title> */}
                <div className="form-steps">
                  {/* <h2 className="h2">Hello Again!</h2> */}
                  <p>
                    Enter email address for you account and we will send you a
                    code to reset your password
                  </p>
                  <Form>
                    <Form.Group
                      className="mb-3 position-relative"
                      controlId="formBasicPassword"
                    >
                      <PhoneInput
                        country={"za"}
                        inputProps={{
                          name: "phone",
                          required: true,
                        }}
                        onlyCountries={["in"]}
                        inputStyle={{
                          display: "block",
                          width: "100%",
                          lineHeight: 1.5,
                          color: "#ffffff",
                          backgroundColor: "#26324B",
                          backgroundClip: "padding-box",

                          borderRadius: "10px",
                          padding: "0.7rem 0.75rem",
                          borderColor: "#26324B",
                          fontSize: "0.86rem",
                          minHeight: "41px",
                          paddingLeft: "50px",
                        }}
                        onChange={(phone) => {
                          // alert("phone", phone);
                          // alert(phone);
                          setMobileNumber(phone);
                        }}
                      />
                    </Form.Group>
                  </Form>

                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ForgotPasswords;
